<template>
	<please-select-utility alert-class="ma-3">
		<data-loader
			ref="dataLoader"
			:watch="{utility}"
			:callback="getCurrentSession"
			:data.sync="selectedSession">
			<template #loading>
				<v-progress-linear indeterminate rounded/>
			</template>
			<template #default>
				<v-alert
					v-if="session===0"
					colored-border border="left" elevation="3"
					type="error" class="ma-3"
				> Няма активна сесия за текущото дружество!
				</v-alert>
				<StationOtchetSchedule :imodel="model" v-else-if="session" :utility="utility" :session="session" />
			</template>
		</data-loader>
	</please-select-utility>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {Mesechen} from "@/views/otcheti/otchetnik_ui/Model";
import StationOtchetSchedule from "@/views/station_data/StationOtchetSchedule.vue";

const model = ITTIModelProvider.getModel(Mesechen);

export default {
	components: {StationOtchetSchedule, PleaseSelectUtility, DataLoader},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'selectedSession',
		}),
	],
	props: [
		'utility',
		'session',
	],
	data(){
		return {
			model,
			selectedSession: this.session,
		}
	},
	watch: {
		session(val){
			if (this.utility && !val) {
				this.$refs.dataLoader.reload();
			}
		},
	},
	methods: {
		async getCurrentSession() {
			this.selectedSession = await model.fetch('getCurrentSession', {utility: this.utility});
		}
	},
}
</script>